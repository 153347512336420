<div class="container-fluid">
  <div class="row sunrise d-flex align-items-center justify-content-center px-3">
    <div class="col col-md-2 text-center">
      <img src="assets/images/logo.png" alt="Manasamskriti" class="img-fluid manasamskriti_logo object-fit-cover">
    </div>
    <div class="col-md-7 d-none d-md-block">
      <div class="d-flex align-items-center justify-content-center">
        <span class="gabreial fs-3">Serve the community by imparting the language and <br class="d-none d-lg-block">cultural aspects of India so as to imbibe the richness and <br class="d-none d-lg-block"> diversity of that tradition and spark the innate, creative <br class="d-none d-lg-block">intent inside everyone</span>
      </div>
    </div>
    <div class="col-md-3 d-none d-md-block">
      <div class=" row text-center d-flex align-items-center justify-content-around ">
        <div class="col">
          <img src="assets/images/natya.png" alt="Natya" class="img-fluid">
        </div>
        <div class="col">
          <img src="assets/images/swara.png" alt="Swara" class="img-fluid">
        </div>
        <div class="col">
          <img src="assets/images/akshara.png" alt="Akshara" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 px-0">
      <nav class="navbar navbar-expand-lg bg-strawberry" >
        <div class="container-fluid" *ngIf="(!showLogOut||logginUserData?.roleId !=1 || undefined)">
          <button class="navbar-toggler  custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <div class="w-100 d-flex align-items-center justify-content-evenly">
              <ul class="navbar-nav">
                <li class="nav-item d-inline-block position-relative " >
                  <a class="nav-link white rounded-5 px-3 d-block"  routerLink="/home" routerLinkActive="active">HOME</a>
                </li>
                <li class="nav-item d-inline-block position-relative" >
                  <a class="nav-link white rounded-5 px-3 d-block"  routerLink="/registration" routerLinkActive="active" (click)="navigateToParentDetailsForm()">REGISTER</a>
                </li>
                <li class="nav-item d-inline-block position-relative" >
                  <a class="nav-link white rounded-5 px-3 d-block" routerLink="/all-courses/0" routerLinkActive="active">COURSES</a>
                </li>
                <!-- <li class="nav-item d-inline-block position-relative" >
                  <a class="nav-link white rounded-5 px-3 d-block"  [attr.disabled]="true" (invalid)="true" routerLinkActive="active">EVENTS</a>
                </li> -->
                <li class="nav-item d-inline-block position-relative" >
                  <a class="nav-link white rounded-5 px-3 d-block" routerLink="/calendar" routerLinkActive="active">CALENDAR</a>
                </li>
                <li class="nav-item d-inline-block position-relative" >
                  <a class="nav-link white rounded-5 px-3 d-block" routerLink="/Donate" routerLinkActive="active">DONATE</a>
                </li>
                <li class="nav-item d-inline-block position-relative" >
                  <a class="nav-link white rounded-5 px-3 d-block" routerLink="/contact-us" routerLinkActive="active">CONTACT US</a>
                </li>
                <li class="nav-item d-inline-block position-relative" >
                  <a class="nav-link white rounded-5 px-3 d-block" routerLink="/join-us" routerLinkActive="active">JOIN US</a>
                </li>
                <li class="nav-item d-inline-block position-relative" >
                  <a class="nav-link white rounded-5 px-3 d-block" routerLink="/FAQ" routerLinkActive="active">FAQ</a>
                </li>
                <li class="nav-item d-inline-block position-relative">
                  <a class="nav-link white rounded-5 px-3 d-block"  *ngIf="!showLogOut" routerLink="/login" routerLinkActive="active">LOGIN</a>
                </li>
                <ul class="navbar-nav">
                  <div class="d-flex justify-content-lg-end justify-content-start px-3 ">
                      <li class="nav-item d-inline-block position-relative" *ngIf="showLogOut">
                          <a class="nav-link white rounded-5 d-block cursor">
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                              </svg>
                          </a>
                          <ul class="submenu position-absolute top-100 left-0 bg-strawberry p-1">
                              <li class="d-block"><a class="nav-link white rounded-5 px-3 d-block cursor"   routerLink="Parent-Details"  routerLinkActive="active">My Profile</a></li>
                              <li class="d-block"><a class="nav-link white rounded-5 px-3 d-block cursor" (click)="logout()">LOGOUT</a></li>
                          </ul>
                      </li>
                  </div>
              </ul>
         
              </ul>
            </div>
          </div>
        </div>
        
    

      </nav>
      <nav class="navbar navbar-expand-lg bg-strawberry" *ngIf="showLogOut && logginUserData?.roleId == 1">
        <div class="container-fluid ">
          <button class="navbar-toggler  custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <div class="w-100 d-flex justify-content-evenly justify-content-lg-start">
              <ul class="navbar-nav text-center">
                <div class="">
                  <li class="nav-item d-lg-inline-block d-block position-relative" *ngIf="showLogOut && logginUserData?.roleId == 1">
                      <a class="nav-link white rounded-5 px-3 d-block cursor" routerLink="Admin/enrollments" routerLinkActive="active">View Enrollments</a>
                  </li>
                  <li class="nav-item d-lg-inline-block d-block position-relative" *ngIf="showLogOut && logginUserData?.roleId == 1">
                    <!--  routerLink="Admin/user-management" -->
                      <a class="nav-link white rounded-5 px-3 d-block cursor" [attr.disabled]="true" (invalid)="true" routerLinkActive="active">User Management</a>
                  </li>
                  <li class="nav-item d-lg-inline-block d-block position-relative" *ngIf="showLogOut && logginUserData?.roleId == 1">
                                        <!--  routerLink="Admin/course-management" -->
                      <a class="nav-link white rounded-5 px-3 d-block cursor" [attr.disabled]="true" (invalid)="true"  routerLinkActive="active">Course Management</a>
                  </li>
              </div>
           
</ul>
            </div>
            <ul class="navbar-nav ">
              <div class="d-flex justify-content-lg-end justify-content-center ">
                  <li class="nav-item d-inline-block position-relative" *ngIf="showLogOut">
                      <a class="nav-link white rounded-5 px-5 mx-2 d-block cursor">
                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                              <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                          </svg>
                      </a>
                      <ul class="submenu position-absolute top-100 left-0 bg-strawberry p-1" style="right:0px">
                          <li class="d-block"><a class="nav-link white rounded-5 px-3 d-block cursor" routerLink="Admin/enrollments" routerLinkActive="active">Admin View</a></li>
                          <li class="d-block"><a class="nav-link white rounded-5 px-3 d-block cursor" (click)="logout()">LOGOUT</a></li>
                      </ul>
                  </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-12 px-md-3">

      <router-outlet></router-outlet>
    </div>
  </div>
  <footer style="flex-shrink: 0;"  class="pt-4">
    <div class="row bg-ash d-flex align-items-center justify-content-between p-3">
        <div class="col-md-8 offset-md-2 px-md-5 text-center text-md-start">
          <small class="d-block lh-1.15">ManaSamskriti is a 501(c)(3) non-profit organization ( DBA of NW Share ) run by volunteers.
                <br>If you like to volunteer or need more info, Please drop us a line at <a href="mailto:info@ManaSamskriti.org" class="text-decoration-underline">info@ManaSamskriti.org</a>
          </small>
        </div>
        <div class="col-md-2 text-center p-2 d-flex align-items-center justify-content-center">
          <small class="p-2 poweredBy_text">Powered by</small>  <img src="assets/images/c2slogo.png" alt="C2S Technologies" class="img-fluid poweredBy_c2s"/>
        </div>
    </div>
  </footer>
</div>
<p-confirmDialog key="toastMsg" #cd [style]="{width: '50vw'}">
  <ng-template pTemplate="footer">
      <a (click)="cd.reject()"><span
          class="text-danger d-inline-block p-3">Cancel</span></a>
      <button type="button" pButton  label="LOGIN" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
