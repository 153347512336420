<div class="container-fluid">
<!-- <div class="row p-2 ">
    <div class="col-xs-2  col-lg-1 col-md-2 text-center col-12 ">
        <img src="assets/images/Alert.gif"  style="width: 57px;" alt="Alert" class="img-fluid"/>
    </div>
    <div class="col-xs-10 col-lg-11 p-0 pt-md-3 col-md-10 col-12">
        <span class="text-danger fw-semibold " style="font-size: 16px;"> Please register before July 15, 2024. Fees will increase to $175 after this date.</span>
    </div>
</div> -->
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12">
            <form [formGroup]="addParentDetails" (ngSubmit)="onSubmit(addParentDetails.value)">
                <div class="row p-4 rounded bg-white card_view shadow">
                    <div class="col-12 p-2 px-5 bg-light-pista border dark-pista rounded d-flex align-items-center" *ngIf="showSuccessMsgForUserVerify">
                        <p class="m-0"><img src="assets/images/checkmark.svg"
                            alt="checkmark" /><span class="m-2">Welcome!</span></p>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="email" class="dark-ask">Email<span class="text-danger"> *</span><span><i
                            class="pi pi-info-circle d-inline-block m-2 blue-berry"
                            pTooltip="All communications will be sent to this email"
                            tooltipPosition="top"></i></span></label>
                            <div class="d-flex align-items-center">
                                <input pInputText name="email" id="email" type="text" class="clsControl form-control"
                        [ngClass]="{'disable_filed': disableEmail === true, '':disableEmail != true}"  formControlName="email" placeholder="Eg: abc@gmail.com" [readonly]="disableEmail"/>
                        <p-progressSpinner *ngIf="userDetailsLoader" class="custom-spinner"  strokeWidth="3"
                        animationDuration=".5s" ></p-progressSpinner>        
                    </div>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('email','Email',addParentDetails) }}</span>
                        </div>
                        <div class="row pt-3 d-flex align-items-center justify-content-around" *ngIf="!showSuccessMsgForUserVerify">
                            <div class="col-12 col-md-8">
                                <p>Please verify email to proceed further.</p>
                            </div>
                            <div class="col-12 col-md-4 text-center text-md-end d-flex align-items-center" >
                                <p-progressSpinner *ngIf="loader" class="custom-spinner"  strokeWidth="3"
                                animationDuration=".5s" ></p-progressSpinner>
                                <button pButton pRipple type="button" label="Verify" class="btn-blue p-0 px-4 py-2 w-100" (click)="verifyUser()"></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="alternateEmail" class="dark-ask">Alternative Email</label>
                        <input pInputText name="alternateEmail" id="alternateEmail" type="text" 
                            class="clsControl form-control" [ngClass]="{'disable_filed': editControl || isValidationNotRequried&&disableEmail  === true, '': editControl || isValidationNotRequried !== true}"
                            formControlName="alternateEmail"
                            placeholder="Eg: abc@gmail.com" (keyup)="validateAlternateEmail()" [readonly]="editControl || isValidationNotRequried"/>
                            <div class="text-danger" >
                                <span class="text-capitalize">{{ validation.validateFormControl('alternateEmail','Alternative Email',addParentDetails) }}</span>
                                <span class="text-capitalize">{{ validateEmailError }}</span>
                            </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="firstName" class="dark-ask">First Name<span class="text-danger"> *</span></label>
                        <input pInputText name="firstName" id="firstName" type="text" class="clsControl form-control"
                        [ngClass]="{'disable_filed': editControl || isValidationNotRequried&&disableEmail === true, '':editControl != true}"  formControlName="firstName" [readonly]="editControl || isValidationNotRequried"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('firstName','First Name',addParentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="lastName" class="dark-ask">Last Name<span class="text-danger"> *</span></label>
                        <input pInputText name="lastName" id="lastName" type="text" class="clsControl form-control"
                        [ngClass]="{'disable_filed': editControl || isValidationNotRequried&&disableEmail === true, '':editControl != true}"   formControlName="lastName" [readonly]="editControl || isValidationNotRequried"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('lastName','Last Name',addParentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="phoneNumber" class="dark-ask">Phone Number<span class="text-danger"> *</span></label>
                        <input pInputText name="phoneNumber"  id="phoneNumber" type="text" (keyup)="validatePhoneNumber()" 
                        [ngClass]="{'disable_filed': editControl ||isValidationNotRequried&&disableEmail === true, '':editControl != true}"   class="clsControl form-control" formControlName="phoneNumber" placeholder="Eg: 4251234567" [readonly]="editControl || isValidationNotRequried"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('phoneNumber','Phone Number',addParentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="alternatePhoneNumber" class="dark-ask">Alternative Phone Number</label>
                        <input pInputText name="alternatePhoneNumber" id="alternatePhoneNumber" type="text"
                        [ngClass]="{'disable_filed': editControl || isValidationNotRequried&&disableEmail === true, '':editControl != true}"  class="clsControl form-control" formControlName="alternatePhoneNumber"  placeholder="Eg: 4251234567" (keyup)="validatePhoneNumber()" 
                             [readonly]="editControl || isValidationNotRequried"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('alternatePhoneNumber','Alternate Phone Number',addParentDetails) }}</span>
                            <span class="text-capitalize">{{ validatePhoneNumberError }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="Country" class="dark-ask">Country<span class="text-danger"> *</span></label>
                        <p-dropdown name="Country" id="Country" [(ngModel)]="selected_CountryId"  [disabled]="editControl || isValidationNotRequried&&disableEmail === true" (onChange)="changeDetails($event?.value?.id)"
                                    formControlName="Country" [ngClass]="{'disable_filed': editControl || isValidationNotRequried === true, '':editControl != true}" class="dropdown d-block studentDetail_dropdown" placeholder="Select Country" [options]="countryApiArray" optionLabel="countryName"></p-dropdown>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('Country','Country',addParentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="State" class="dark-ask">State<span class="text-danger">*</span></label>
                       <div *ngIf="this.storeStateApiArray?.length>0 ;else noState">
                        <p-dropdown name="State" id="State"   [disabled]="editControl ||isValidationNotRequried&&disableEmail === true" 
                        formControlName="State" [ngClass]="{'disable_filed': editControl || isValidationNotRequried === true, '':editControl != true}" class="dropdown d-block studentDetail_dropdown" placeholder="Select State" [options]="storeStateApiArray" optionLabel="stateName"></p-dropdown>
                       </div>
                       <ng-template #noState>
                            <input pInputText name="State"  id="State" type="text"
                            [ngClass]="{'disable_filed': editControl || isValidationNotRequried&&disableEmail=== true, '':editControl != true}"  [readonly]="editControl || isValidationNotRequried"  class="clsControl form-control" formControlName="State" placeholder="State" />
                    </ng-template>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('State','State',addParentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="City" class="dark-ask">Town/City<span class="text-danger">*</span></label>
                        <input pInputText name="City"  id="City" type="text"  
                        [ngClass]="{'disable_filed': editControl || isValidationNotRequried&&disableEmail === true, '':editControl != true}"   class="clsControl form-control" formControlName="city" placeholder="Town/City" [readonly]="editControl || isValidationNotRequried"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('city','City',addParentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="zipCode" class="dark-ask">Zip Code<span class="text-danger">*</span></label>
                        <input pInputText name="zipCode"  id="zipCode" type="text"  
                        [ngClass]="{'disable_filed': editControl || isValidationNotRequried&&disableEmail === true, '':editControl != true}"   class="clsControl form-control" formControlName="zipCode" placeholder="Zip Code" [readonly]="editControl || isValidationNotRequried"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('zipCode','Zip Code',addParentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-2 pl-5 px-md-5">
                        <label for="address" class="dark-ask">Address Line 1<span class="text-danger"> *</span></label>
                        <textarea rows="5" cols="30" class="clsControl form-control" 
                        [ngClass]="{
                            'disable_filed': editControl === true || (isValidationNotRequried && disableEmail) === true
                          }"
                            formControlName="address" [readonly]="editControl || isValidationNotRequried" [disabled]="isValidationNotRequried"></textarea>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('address','Address',addParentDetails) }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-end p-0 py-3">
                        <button pButton pRipple type="submit" label="Next" class="btn-blue btn-lg"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <p-dialog  [(visible)]="showOTPScreen" [style]="{width: '50vw'}" [modal]=true class="otp_header">
        <div class="row">
            <div class="col-12">
                <h2 class="fw-bold">Verify your email address</h2>
            </div>
            <div class="col-12 bg-light-pista border dark-pista rounded">
                <p class="p-2 m-0">Please check your email account for the verification code we just sent you and enter that code in the below box</p>
            </div>
            <div class="col-12 col-md-6">
                <p class="dark-ask pt-4">Enter OTP</p>
                <form [formGroup]="otpForm" (ngSubmit)="onOTPSubmit(otpForm.value)">
                    <div class="d-flex align-items-center">
                        <input pInputText name="firstDigit" maxlength="1" id="firstDigit" type="text" #firstDigit
                        class="clsControl form-control w-10 mx-2 text-center" formControlName="firstDigit"  (keyup)="otpController($event, secondDigit, '', 1)"/>
                        <input pInputText name="secondDigit" maxlength="1" id="secondDigit" type="text" #secondDigit
                        class="clsControl form-control w-10 mx-2 text-center" formControlName="secondDigit" (keyup)="otpController($event, thirdDigit, firstDigit, 1)"/>
                        <input pInputText name="thirdDigit" maxlength="1" id="thirdDigit" type="text" #thirdDigit
                        class="clsControl form-control w-10 mx-2 text-center" formControlName="thirdDigit" (keyup)="otpController($event, fourthDigit, secondDigit, 1)"/>
                        <input pInputText name="fourthDigit" maxlength="1" id="fourthDigit" type="text" #fourthDigit
                        class="clsControl form-control w-10 mx-2 text-center" formControlName="fourthDigit" (keyup)="otpController($event, '', thirdDigit, 1)"/>
                    </div>
                    <p *ngIf="showOTPInvalidError" class="p-2 text-danger">{{otpErrorMsg}}</p>
                    <p *ngIf="this.otpForm.invalid&&this.otpError" class="p-2 text-danger">Please fill OTP</p>
                    <a class="d-inline-block dark-ask pt-2 cursor" (click)="verifyUser()">Resend OTP</a>
                    <div class="row">
                        <div class="col-12 pt-5 d-flex align-items-center">
                            <button pButton pRipple type="submit" label="Continue" id="continueOtpVerify" class="btn-lg btn-blue"></button>
                            <p-progressSpinner *ngIf="loader" class="custom-spinner"  strokeWidth="3"
                            animationDuration=".5s" ></p-progressSpinner>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </p-dialog>
</div>